<template>
  <div class="smallTitle">
    <div class="leftBg"></div>
    <div class="rightTitle">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.smallTitle {
  width: 100%;
  padding: 0 100px;
  display: flex;
  align-items: center;
  .leftBg {
    width: 100px;
    height: 40px;
    background: url("../assets/images/caseReport/section_title.png") no-repeat
      center;
    background-size: 100% 100%;
    margin-right: 22px;
  }
  .rightTitle {
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 66px;
  }
}
</style>
